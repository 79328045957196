export default {
  namespaced: true,
  state: {
    listaRelatorioCarregado: false,
    listaRelatorio: null,
  },
  mutations: {
    SET_CARREGADO(state, valor) {
      state.listaRelatorioCarregado = valor;
    },
    SET_BANNER(state, data) {
      state.listaRelatorio = data;
    },
  },
};
