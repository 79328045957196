<template>
  <div id="main" :class="layout">
    <transition name="fade">
      <Loading v-if="loading" />
    </transition>
    <transition name="fade" mode="out-in">
      <ModalModerar v-if="moderar" />
      <ModalVerProdutos v-if="modalVerProdutos" />
      <ModalMensagem v-if="modalMensagem" />
    </transition>
    <transition name="fade" mode="out-in">
      <component :is="modal" v-if="modal" />
    </transition>
    <MensagemAlerta />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
const Loading = () => import("@/components/estrutura/loading/Loading.vue");
const ModalMensagem = () => import("@/components/estrutura/modal/_ModalMensagem.vue");
const ModalModerar = () => import("@/components/estrutura/modal/_ModalModerar.vue");
const ModalVerProdutos = () => import("@/components/estrutura/modal/_ModalVerProdutos.vue");
const MensagemAlerta = () => import("@/components/estrutura/alerta/MensagemAlerta.vue");
const ModalPromocaoQuantidade = () => import("@/components/estrutura/modal/_ModalPromocaoQuantidade.vue");
const ModalPromocaoProdutos = () => import("@/components/estrutura/modal/_ModalPromocaoProdutos.vue");
const ModalPromocaoValor = () => import("@/components/estrutura/modal/_ModalPromocaoValor.vue");
const ModalPromocaoPontuam = () => import("@/components/estrutura/modal/_ModalPromocaoPontuam.vue");
const ModalPromocaoRegulamento = () => import("@/components/estrutura/modal/_ModalPromocaoRegulamento.vue");

export default {
  components: {
    Loading,
    ModalMensagem,
    ModalModerar,
    ModalVerProdutos,
    MensagemAlerta,
    ModalPromocaoQuantidade,
    ModalPromocaoProdutos,
    ModalPromocaoValor,
    ModalPromocaoPontuam,
    ModalPromocaoRegulamento
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
    loading() {
      return this.$store.state.loading;
    },
    modalMensagem() {
      return this.$store.state.modalMensagem.ativado;
    },
    modal() {
      return this.$store.state.modalCustomizado;
    },
    moderar() {
      return this.$store.state.modal.modalAtivado && this.$store.state.modal.tipoModal == "modalModerar";
    },
    modalVerProdutos() {
      return this.$store.state.modal.modalAtivado && this.$store.state.modal.tipoModal == "modalVerProdutos";
    },
    
  },
};
</script>
