<template>
	<div class="login-main" :class="cor">
		<div class="modal-login">
			<div class="container-login-img">
				<LogoLogin />
			</div>
			<div class="container-login">
				<form @submit.prevent>
					<div class="container-login-inputs">
						<input type="email" placeholder="Login" v-model.trim="login.email"
							@blur="$v.login.email.$touch()" />
						<div class="container-login-inputs-senha">
							<input ref="senha1" type="password" placeholder="Senha" v-model.trim="login.senha"
								@blur="$v.login.senha.$touch()" />
							<i class="icone-olho-senha" :class="{ active: senha1 }"
								@click="toggleMostrarSenha('senha1')"></i>
						</div>
					</div>
					<div class="container-login-botoes" data-teste>
						<button class="btn btn-laranja-login" @click="fazerLogin" :disabled="$v.login.$invalid">
							Entrar
						</button>
					</div>
				</form>
			</div>
			<div class="login-copy">
				<p>Powered by
					<LogoFullbar />
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { required } from "vuelidate/lib/validators";
import { enviarLogin } from "@/services/login/login";
import LogoLogin from "@/components/elementos/logos/LogoLogin.vue";
import LogoFullbar from "@/components/elementos/logos/LogoFull.vue";
import BgLogin from "@/assets/images/tema/login-bg.svg";
export default {
	name: "login",
	directives: {
		mask: AwesomeMask
	},
	components: {
		LogoLogin,
		LogoFullbar
	},
	data() {
		return {
			bgLogin: BgLogin,
			cor: null,
			senha1: false,
			login: {
				email: "",
				senha: ""
			}
		};
	},
	validations: {
		login: {
			email: {
				required
			},
			senha: {
				required
			}
		}
	},
	methods: {
		toggleMostrarSenha(ref) {
			if (this.$refs[ref].type == "password") {
				this[ref] = true;
				this.$refs[ref].type = "text";
			} else {
				this[ref] = false;
				this.$refs[ref].type = "password";
			}
		},
		fazerLogin() {
			localStorage.clear();
			const data = {
				email: this.login.email,
				senha: this.login.senha
			};
			enviarLogin(data);
		}
	},
	created() {
		if (this.$route.query.cor) {
			this.cor = this.$route.query.cor;
		}
	}
};
</script>

<style lang="scss">
.login-main {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	min-height: 600px;
	height: 100vh;
	padding: 20px;
	background: linear-gradient(#f7941d 0%, #e64e20 100%);

	.btn-laranja-login {
		margin-top: 20px;
		color: #fff;
		background: linear-gradient(#50b07c 0%, #a2e323 100%);

		&:hover {
			opacity: 0.8;
		}
	}

	.modal-login {
		width: 100%;
		max-width: 450px;
	}

	.container-login-img {
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		width: 100%;
		margin: 0 auto;
		padding: 0 20px;

		img {
			width: 100%;
			max-width: 450px;
			height: auto;
			max-height: 250px;
			pointer-events: none;

			@media (max-width: 700px) {
				max-height: 150px;
			}
		}
	}

	.container-login {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		margin: 30px 0 50px 0;

		>form {
			width: 100%;
		}

		.container-login-inputs-senha {
			position: relative;
			margin-bottom: 35px;

			>input {
				box-sizing: border-box;
				margin-bottom: 0;
				padding-right: 45px;
			}
		}
	}

	input {
		font-family: "Economica", sans-serif;
		font-size: 20px;
		box-sizing: border-box;
		width: 100%;
		margin-bottom: 20px;
		padding: 5px;
		color: $branco-1;
		border: none;
		border-bottom: 1px solid $branco-1;
		outline: none;
		background: none;

		&::placeholder {
			color: $branco-1;
		}

		&:-webkit-autofill {
			transition: background-color 5000s ease-in-out 0s;
			background-color: transparent !important;
			-webkit-text-fill-color: $branco-1;
		}
	}

	.login-copy {
		width: 100%;
		text-align: center;

		p {
			font-size: 18px;
			color: $branco-1;
		}
	}
}
</style>
