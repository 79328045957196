import CadastroCampanhas from "@/views/logadas/cadastro-campanhas/_CadastroCampanhas.vue";
import CadastroBannerHome from "@/views/logadas/cadastro-banner-home/_CadastroBannerHome.vue";
import CadastroBannerTreinamentos from "@/views/logadas/cadastro-banner-treinamentos/_CadastroBannerTreinamentos.vue";
import CadastroNotificacoes from "@/views/logadas/notificacoes/_CadastroNotificacoes.vue";
import CadastroPremios from "@/views/logadas/cadastro-premios/_CadastroPremios.vue";

const routes = [
    {
        path: "/cadastro-banner-home",
        name: "Cadastrar Banner",
        component: CadastroBannerHome,
        redirect: "/cadastro-banner-home/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Banner Home",
                icone: "IconBannerHome"
            }
        },
        children: [
            {
                path: "listar",
                name: "listar-cadastro-banner-home",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-banner-home/CadastroBannerHomeListar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "adicionar-cadastro-banner-home",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-banner-home/CadastroBannerHomeAdicionar.vue"
                    ),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar",
                name: "editar-cadastro-banner-home-raiz",
                redirect: "/cadastro-banner-home/listar",
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "editar-cadastro-banner-home",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-banner-home/CadastroBannerHomeEditar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
    {
        path: "/cadastro-campanhas",
        name: "Cadastro de Campanhas",
        component: CadastroCampanhas,
        redirect: "/cadastro-campanhas/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Cadastro de Campanhas",
                icone: "IconCampanha"
            }
        },
        children: [
            {
                path: "listar",
                name: "listar-cadastro-de-campanhas",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-campanhas/CadastroCampanhasListar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "AdicionarCampanha",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-campanhas/CadastroCampanhasAdicionar.vue"
                    ),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar",
                name: "editar-cadastro-de-campanhas",
                redirect: "/cadastro-campanhas/listar",
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "editar-cadastro-campanhas",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-campanhas/CadastroCampanhasEditar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
    {
        path: "/cadastro-premios",
        name: "Cadastrar Prêmios",
        component: CadastroPremios,
        redirect: "/cadastro-premios/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Cadastrar Prêmios",
                icone: "IconPremios"
            }
        },
        children: [
            {
                path: "listar",
                name: "ListarPremios",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-premios/CadastroPremiosListar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "AdicionarPremio",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-premios/CadastroPremiosAdicionar.vue"
                    ),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "EditarPremio",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-premios/CadastroPremiosEditar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
    {
        path: "/cadastro-notificacoes",
        name: "Cadastrar Notificações",
        component: CadastroNotificacoes,
        redirect: "/cadastro-notificacoes/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Cadastrar Notificações",
                icone: "IconNotificacao"
            }
        },
        children: [
            {
                path: "listar",
                name: "ListarNotificacoes",
                component: () =>
                    import(
                        "@/views/logadas/notificacoes/CadastroNotificacoesListar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "AdicionarNotificacao",
                component: () =>
                    import(
                        "@/views/logadas/notificacoes/CadastroNotificacoesAdicionar.vue"
                    ),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "EditarNotificacao",
                component: () =>
                    import(
                        "@/views/logadas/notificacoes/CadastroNotificacoesEditar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
    {
        path: "/cadastro-banner-treinamentos",
        name: "Cadastrar Treinamento",
        component: CadastroBannerTreinamentos,
        redirect: "/cadastro-banner-treinamentos/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Cadastrar Treinamento",
                icone: "IconTreinamento"
            }
        },
        children: [
            {
                path: "listar",
                name: "listar-cadastro-banner-treinamentos",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-banner-treinamentos/CadastroBannerTreinamentosListar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "adicionar-cadastro-banner-treinamentos",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-banner-treinamentos/CadastroBannerTreinamentosAdicionar.vue"
                    ),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar",
                name: "editar-cadastro-banner-treinamentos-raiz",
                redirect: "/cadastro-banner-treinamentos/listar",
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "editar-cadastro-banner-treinamentos",
                component: () =>
                    import(
                        "@/views/logadas/cadastro-banner-treinamentos/CadastroBannerTreinamentosEditar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
]

export default routes