import Powerbi from "@/views/logadas/powerbi/_Powerbi.vue";

const routes = [
    {
        path: "/powerbi",
        name: "Relatórios",
        component: Powerbi,
        meta: {
            perfilPermitido: [2, 3, 5],
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Relátorios",
                icone: "IconRelatorioResgate"
            }
        }
    },
]

export default routes