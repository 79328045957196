export default {
  namespaced: true,
  state: {
    listaBannerCarregado: false,
    listaBanner: null,
  },
  mutations: {
    SET_CARREGADO(state, valor) {
      state.listaBannerCarregado = valor;
    },
    SET_BANNER(state, data) {
      state.listaBanner = data;
    },
  },
};
