import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Vuebar from 'vuebar';
import JsonExcel from "vue-json-excel";
import '@/plugins';
import '@/registerServiceWorker';
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueMask from 'v-mask';

Vue.use(VueMask);
Vue.use(Vuebar);
Vue.component('downloadExcel', JsonExcel);
Vue.use(CKEditor);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
