import LayoutLogado from "@/views/_LayoutLogado.vue";
import Home from "./Home";
import Cadastro from "./Cadastro"
import Login from "./Login"
import Processamento from "./Processamento"
import Relatorios from "./Relatorios"
import Sac from "./Sac"
import Usuarios from "./Usuarios"
import Autorizadas from "./Autorizadas"
import Gerenciamento from "./Gerenciamento"

const routes = [
	{
		path: "/",
		redirect: "home",
		component: LayoutLogado,
		children: [
			...Home,
			...Cadastro,
			...Autorizadas,
			...Processamento,
			...Relatorios,
			...Sac,
			...Usuarios,
			...Gerenciamento
		]
	},
	{
		path: "*",
		redirect: "/"
	},
	...Login,
]

export default routes