export default {
  namespaced: true,
  state: {
    listaPromocoesCarregado: false,
    listaPromocoes: null,
    promocaoQuantidade: null,
    modalPromocaoQuantidade: null,
    promocaoProdutos: null,
    modalPromocaoProdutos: null,
    promocaoValor: null,
    modalPromocaoValor: null,
    promocaoPontuam: null,
    modalPromocaoPontuam: null,
    opcoesMecanicas: null,
    modalPromocaoRegulamento: false,
    
    promocaoAceitaRegulamento: "",
    precisaDeRegulamento: [{id: 1, name: "NÃO"}, {id: 2, name: "SIM"}],
    promocaoPromoshotData: null,
    precisaDeRegulamentoObrigatorio: [{id: 1, name: "NÃO"}],
    promocaoPromoshot: null,

  },
  mutations: {
    SET_CARREGADO(state, valor) {
      state.listaPromocoesCarregado = valor;
    },
    SET_PROMOCOES(state, data) {
      state.listaPromocoes = data;
    },
    SET_PROMOCAO_QUANTIDADE(state, data) {
      state.promocaoQuantidade = data;
    },
    SET_MODAL_PROMOCAO_QUANTIDADE(state, data) {
      state.modalPromocaoQuantidade = data;
    },
    SET_PROMOCAO_PRODUTOS(state, data) {
      state.promocaoProdutos = data;
    },
    SET_MODAL_PROMOCAO_PRODUTOS(state, data) {
      state.modalPromocaoProdutos = data;
    },
    SET_PROMOCAO_VALOR(state, data) {
      state.promocaoValor = data;
    },
    SET_MODAL_PROMOCAO_VALOR(state, data) {
      state.modalPromocaoValor = data;
    },
    SET_PROMOCAO_PONTUAM(state, data) {
      state.promocaoPontuam = data;
    },
    SET_MODAL_PROMOCAO_PONTUAM(state, data) {
      state.modalPromocaoPontuam = data;
    },
    SET_OPCOES_MECANICAS(state, data) {
      state.opcoesMecanicas = data;
    },
    SET_MODAL_PROMOCAO_REGULAMENTO(state, data) {
      state.modalPromocaoRegulamento = data;
    },
    SET_PROMOCAO_ACEITA_REGULAMENTO(state, data) {
      state.promocaoAceitaRegulamento = data;
    },
    SET_PROMOCAO_PROMOSHOT_DATA(state, data) {
      state.promocaoPromoshotData = data;
    },
    SET_PRECISA_DE_REGULAMENTO_OBRIGATORIO(state, data) {
      state.precisaDeRegulamentoObrigatorio = data;
    },
    SET_PROMOCAO_PROMOSHOT(state, data) {
      state.promocaoPromoshot = data;
    },
  },
};
