import Usuarios from "@/views/logadas/usuarios/_Usuarios.vue";

const routes = [
    {
        path: "/usuarios",
        name: "Usuarios",
        component: Usuarios,
        redirect: "/usuarios/listar",
        children: [
            {
                path: "listar",
                name: "listar-usuarios",
                component: () =>
                    import("@/views/logadas/usuarios/UsuariosListar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "usuario/:id/:perfil",
                name: "detalhes-usuario",
                component: () =>
                    import("@/views/logadas/usuarios/UsuariosEditar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                },
            },
            {
                path: "adicionar",
                name: "adicionar-usuario",
                component: () =>
                    import("@/views/logadas/usuarios/UsuariosAdicionar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "usuario/:id/nova-ocorrencia/",
                name: "nova-ocorrencia",
                component: () =>
                    import("@/views/logadas/sac/ocorrencias/OcorrenciaAdicionar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
        ]
    },
]


export default routes