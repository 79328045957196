import axios from "axios";
import store from "@/store";
// import router from "@/router";
// import { buscarUsuario } from '@/services/login/buscarUsuario';
import { _rotasAPI } from "@/services/_caminhosApi";
import router from "../../router";
//
export function enviarLogin(data) {
	store.commit("SET_ADICIONAR_LOADING");
	axios
		.post(_rotasAPI().enviarLogin, data)
		.then(resp => {
			if (resp.status == 200) {
				localStorage.setItem("token", resp.data.token);
				localStorage.setItem("powerbi", JSON.stringify(resp.data.powerBi));
				localStorage.setItem('perfil', resp.data.perfilAcessoPainelId)
				localStorage.setItem('user-name', resp.data.usuario.nome)
				store.commit("user/SET_LOGADO", true);
				store.commit("user/SET_NOME", resp.data.usuario.nome);
				store.commit("user/SET_PERFIL_RELATORIO", resp.data.perfilAcessoPainelId);
				// resp.data.somenteRelatorio == 3 ? router.push("/powerbi") : router.push({ name: "Home" });
				// buscarUsuario(resp.data.token).then(() => {
				// });
				router.push({ name: 'Home' })
				
			} else {
				store.commit("SET_MODAL", {
					ativado: true,
					mensagem: resp.data.message,
					tipo: "erro"
				});
			}
		})
		.catch(erro => {
			store.commit("user/SET_LOGADO", false);
			window.localStorage.removeItem("token");
			store.commit("SET_MODAL", {
				ativado: true,
				mensagem: String(erro.response.data),
				tipo: "erro"
			});
		})
		.finally(() => {
			store.commit("SET_REMOVER_LOADING");
		});
}
