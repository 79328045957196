export default {
  namespaced: true,
  state: {
    listaClientesCarregado: false,
    listaClientes: null,
  },
  mutations: {
    SET_CARREGADO(state, valor) {
      state.listaClientesCarregado = valor;
    },
    SET_CLIENTES(state, data) {
      state.listaClientes = data;
    },
  },
};
