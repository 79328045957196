import Gerenciamento from "@/views/logadas/gerenciamento/_Gerenciamento.vue";

const routes = [
    {
        path: "/gerenciamento-sistema",
        name: "Gerenciamento",
        component: Gerenciamento,
        redirect: "/gerenciamento-sistema/status",
        children: [
            {
                path: "detalhes/:id",
                name: "GerenciamentoDetalhes",
                component: () => import("@/views/logadas/gerenciamento/GerenciamentoDetalhes.vue"),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "status",
                name: "GerenciamentoStatus",
                component: () => import("@/views/logadas/gerenciamento/GerenciamentoListar.vue"),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
        ]
    }
]

export default routes