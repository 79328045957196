<template>
  <div id="layout-logado">
    <CompHeader />
    <div class="container-interna" :class="{ desactive: !menuAtivo }">
      <Sidebar class="container-sidebar" />
      <transition mode="out-in" name="fade">
        <router-view class="container-conteudo"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
const CompHeader = () => import('@/components/estrutura/header/Header.vue');
const Sidebar = () => import('@/components/estrutura/sidebar/Sidebar.vue');
export default {
  name: 'interna',
  components: {
    CompHeader,
    Sidebar,
  },
  computed: {
    menuAtivo() {
      return this.$store.state.menuAtivado;
    },
  },
};
</script>

<style lang="scss">
#layout-logado {
  overflow: hidden;
  .container-interna {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);
    .container-sidebar {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      overflow: auto;
      overflow-x: hidden;
      width: 270px;
      height: 100%;
      transition: 0.3s;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    }
    .container-conteudo {
      position: absolute;
      top: 0;
      right: 0;
      overflow-x: hidden;
      box-sizing: border-box;
      box-sizing: border-box;
      width: calc(100% - 270px);
      height: 100%;
      transition: 0.3s;
      .conteudo-interno {
        overflow: auto;
        box-sizing: border-box;
        width: 100%;
        min-height: 100%;
        padding: 0;
        transition: 0.3s;
        &.sub {
          width: calc(100% - 200px);
          @media (max-width: 1000px) {
            width: 100%;
          }
        }
      }
    }
    &.desactive {
      .container-sidebar {
        width: 60px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      }
      .container-conteudo {
        width: calc(100% - 60px);
      }
    }
    @media (max-width: 700px) {
      height: calc(100vh - 55px);
      .container-sidebar {
        z-index: 90;
      }
      .container-conteudo {
        width: calc(100% - 60px);
      }
      &.desactive {
        .container-sidebar {
          width: 60px;
        }
        .container-conteudo {
          width: calc(100% - 60px);
        }
      }
    }
  }
}
</style>
