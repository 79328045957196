import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/user.js';
import cupom from '@/store/cupom.js';
import clientes from '@/store/clientes.js';
import produto from '@/store/produto.js';
import promocoes from '@/store/promocoes.js';
import cadastroBanner from '@/store/cadastroBanner.js';
import relatorioResgate from '@/store/relatorioResgate.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    cupom,
    clientes,
    produto,
    promocoes,
    cadastroBanner,
    relatorioResgate
  },
  state: {
    layout: 'layout-claro',
    loading: 0,
    menuAtivado: true,
    paginaAtualizada: false,
    modalMensagem:{
      ativado: false,
      tipo: '',
      mensagem: '',
      func: () => {}
    },
    modalCustomizado: '',
    modalConfirmacao: '',
    modalConfirmacaoAtivado: false,
    modalOcorrenciasAtivado: false,
    modal: {
      modalAtivado: false,
      mensagemModal: '',
      tipoModal: '',
    },
  },
  mutations: {
    SET_TEMA(state, layout) {
      state.layout = layout;
    },
    SET_ADICIONAR_LOADING(state) {
      ++state.loading;
    },
    SET_REMOVER_LOADING(state) {
      --state.loading;
    },
    SET_SIDEBAR(state, valor) {
      state.menuAtivado = valor;
    },
    SET_PAGINA_ATUALIZADA(state, atualizada) {
      state.paginaAtualizada = atualizada;
    },
    SET_MODAL_MENSAGEM(state, config) {
      state.modalMensagem.ativado = config.ativado;
      state.modalMensagem.tipo = config.tipo;
      state.modalMensagem.mensagem = config.mensagem;
      state.modalMensagem.func = config.func;
    },
    SET_MODAL_CONFIRMACAO(state, confirmacao){
      state.modalConfirmacaoAtivado = confirmacao.ativado;
    },
    SET_MODAL_OCORRENCIAS(state, confirmacao){
      state.modalOcorrenciasAtivado = confirmacao;
    },
    SET_MODAL_CUSTOMIZADO(state, modal) {
      state.modalCustomizado = modal;
    },
    SET_MODAL(state, modal) {
      state.modal.modalAtivado = modal.ativado;
      state.modal.mensagemModal = modal.mensagem;
      state.modal.tipoModal = modal.tipo;
      state.modal.paramsModal = modal.parametro;
      setTimeout(function () {
        state.modal.modalAtivado = false
      }, 4000);
    },
  },

  actions: {},
});
