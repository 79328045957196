import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import allRoutes from "./routes"


Vue.use(VueRouter);

const routes = [
	...allRoutes,
];

const router = new VueRouter({
	mode: "history",
	scrollBehavior: () => ({ y: 0 }),
	routes,
	linkActiveClass: 'active',
});

function semValidacao(to) {
	const token = localStorage.getItem("token");
	return (
		to.meta.precisaAutenticacao == true &&
		!store.state.user.estaLogado &&
		!token
	);
}

function verificarPermissoes(to) {
	const perfilId = JSON.parse(localStorage.getItem("perfil"));
	if (to.meta.perfilPermitido && !to.meta.perfilPermitido.some(item => item == perfilId)) {
		return true;
	}
}

router.beforeEach((to, from, next) => {
	// fecharElementosTela();

	if (semValidacao(to)) {
		return next("/login");
	}
	if (verificarPermissoes(to)) {
		store.commit("SET_MODAL", {
			ativado: true,
			mensagem: 'Sem premissão',
			tipo: "erro",
		})
		return next("/home");
	}
	next();
});

export default router;