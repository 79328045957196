<template>
  <div id="home">
    <h1>Bem-vindo ao <span>Tec Club</span>, {{ nomeUsuario }}</h1>
    <h2>Cuidando de quem cuida dos consumidores todos os dias!</h2>
  </div>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    nomeUsuario() {
      return localStorage.getItem('user-name')
    },
  },
};
</script>

<style lang="scss">
#home {
  padding: 40px 20px 150px 20px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 700px) {
    padding: 30px 20px 80px 20px;
  }
  .home-favs {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto;
    @media (max-width: 700px) {
      margin: 10px auto 40px auto;
    }
    > div {
      cursor: pointer;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      width: calc(100% / 4 - 20px);
      margin: 10px;
      background: rgba(#fff, 0.5);
      position: relative;
      border-radius: 10px;
      position: relative;
      transition: top 0.3s;
      top: 0;
      @media (max-width: 800px) {
        width: calc(100% / 3 - 20px);
      }
      @media (max-width: 550px) {
        width: calc(100% / 2 - 20px);
      }
      &:after {
        content: '';
        display: block;
        padding-bottom: 80%;
      }
      &:hover {
        top: -5px;
      }
    }
  }
  h1 {
    font-size: 45px;
    margin-bottom: 15px;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    transition: 0.3s;
    span {
      transition: 0.3s;
    }
    @media (max-width: 700px) {
      font-size: 30px;
    }
  }
  h2 {
      font-size: 20px;
      transition: 0.3s;
     @media (max-width: 700px) {
      font-size: 12px;
    }
  }
  p {
    font-size: 35px;
    font-weight: 500;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    transition: 0.3s;
    @media (max-width: 700px) {
      font-size: 22px;
      max-width: 300px;
    }
  }
}
</style>
