import Sac from "@/views/logadas/sac/_Sac.vue";

const routes = [

    {
        path: "/sac",
        name: "SAC",
        component: Sac,
        redirect: "/sac/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Sac",
                icone: "IconSac"
            }
        },
        children: [
            {
                path: "listar",
                name: "ocorrencia-listar",
                component: () =>
                    import("@/views/logadas/sac/SacListar.vue"),
                meta: {
                    precisaAutenticacao: true,
                    perfilPermitido: [2, 4, 5]
                }
            },
            {
                path: "ocorrencia/:ocorrenciaId",
                name: "detalhes-ocorrencia",
                component: () =>
                    import("@/views/logadas/sac/ocorrencias/OcorrenciaEditar.vue"),
                meta: {
                    perfilPermitido: [2, 4, 5],
                    precisaAutenticacao: true
                }
            },
        ]
    },
]


export default routes