import Ranking from "@/views/logadas/ranking/_Ranking.vue";
import Treinamentos from "@/views/logadas/treinamentos/_Treinamentos.vue";
import Campanhas from "@/views/logadas/campanhas/_Campanhas.vue";
import Performance from "@/views/logadas/performance/_Performance.vue";

const routes = [
    {
        path: "/campanhas",
        name: "Processar Campanhas",
        component: Campanhas,
        redirect: "/campanhas/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: " Processar Campanhas",
                icone: "IconProcessarCampanha"
            }
        },
        children: [
            {
                path: "listar",
                name: "listar-campanha",
                component: () =>
                    import("@/views/logadas/campanhas/CampanhasListar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "adicionar-campanha",
                component: () =>
                    import("@/views/logadas/campanhas/CampanhasAdicionar.vue"),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "editar-campanha",
                component: () =>
                    import("@/views/logadas/campanhas/CampanhasEditar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
    {
        path: "/treinamentos",
        name: "Processar Treinamentos",
        component: Treinamentos,
        redirect: "/treinamentos/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: " Processar Treinamento",
                icone: "IconProcessamento"
            }
        },
        children: [
            {
                path: "listar",
                name: "listar-promocoes",
                component: () =>
                    import("@/views/logadas/treinamentos/TreinamentosListar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "adicionar-treinamentos",
                component: () =>
                    import("@/views/logadas/treinamentos/TreinamentosAdicionar.vue"),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar",
                name: "editar-promocoes-raiz",
                redirect: "/treinamentos/listar",
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "editar-treinamentos",
                component: () =>
                    import(
                        /* webpackChunkName: "promocoes" */ "@/views/logadas/treinamentos/TreinamentosEditar.vue"
                    ),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
    {
        path: "/ranking",
        name: "Processar Ranking",
        component: Ranking,
        redirect: "/ranking/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Processar Ranking",
                icone: "IconRanking"
            }
        },
        children: [
            {
                path: "listar",
                name: "listar-ranking",
                component: () =>
                    import("@/views/logadas/ranking/RankingListar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "adicionar-ranking",
                component: () =>
                    import("@/views/logadas/ranking/RankingAdicionar.vue"),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar",
                name: "editar-ranking-raiz",
                redirect: "/ranking/listar",
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "editar-ranking",
                component: () =>
                    import("@/views/logadas/ranking/RankingEditar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
    {
        path: "/performance",
        name: "Processar Performance",
        component: Performance,
        redirect: "/performance/listar",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Processar Performance",
                icone: "IconPerformance"
            }
        },
        children: [
            {
                path: "listar",
                name: "listar-performance",
                component: () =>
                    import("@/views/logadas/performance/PerformanceListar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "adicionar-performance",
                component: () =>
                    import("@/views/logadas/performance/PerformanceAdicionar.vue"),
                meta: {
                    perfilPermitido: [2],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar",
                name: "editar-performance-raiz",
                redirect: "/performance/listar",
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "editar-performance",
                component: () =>
                    import("@/views/logadas/performance/PerformanceEditar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            }
        ]
    },
]

export default routes