import { render, staticRenderFns } from "./_Powerbi.vue?vue&type=template&id=2c29363d&scoped=true"
import script from "./_Powerbi.vue?vue&type=script&lang=js"
export * from "./_Powerbi.vue?vue&type=script&lang=js"
import style0 from "./_Powerbi.vue?vue&type=style&index=0&id=2c29363d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c29363d",
  null
  
)

export default component.exports