export function _imagemCupom(img) {
	return `${process.env.VUE_APP_URL_IMG_S3}/cupom/${img}`;
}

// LOGIN
export function _rotasAPI() {
	return {
		// Authentication
		enviarLogin: `${process.env.VUE_APP_API_BASE}/Authentication/LoginPainel`,
		buscarUsuario: `${process.env.VUE_APP_API_BASE}/Usuario/BuscarUsuarioPorId`,

		// Upload
		uploadImagem: `${process.env.VUE_APP_API_BASE}/Upload/Enviar`,

		// Cupons
		listarCupom: `${process.env.VUE_APP_API_BASE}/Cupom/Listar`,
		detalhesCupom: `${process.env.VUE_APP_API_BASE}/Cupom/Detalhes?id=`,
		moderarCupom: `${process.env.VUE_APP_API_BASE}/Cupom/Moderar`,

		// listarStatus: `${process.env.VUE_APP_API_BASE}/Cupom/ListarStatus`,
		alterarStatus: `${process.env.VUE_APP_API_BASE}/cupom/LiberarPontuacao`,
		produtoParticipante: `${process.env.VUE_APP_API_BASE}/ProdutoParticipante/Listar?promocaoId=`,
		relatorioCupom: `${process.env.VUE_APP_API_BASE}/Relatorio/CupomExportar`,

		// Clientes
		listarClientes: `${process.env.VUE_APP_API_BASE}/Cliente/Listar`,
		buscarCliente: `${process.env.VUE_APP_API_BASE}/Cliente/BuscarPorId?id=`,
		cadastrarCliente: `${process.env.VUE_APP_API_BASE}/Cliente/Cadastrar`,
		editarCliente: `${process.env.VUE_APP_API_BASE}/Cliente/Editar`,

		//Usuários
		listarUsuario: `${process.env.VUE_APP_API_BASE}/Usuario/Listar`,
		detalhesUsuario: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/BuscarPorId/`,
		detalhesUsuarioAdm: `${process.env.VUE_APP_API_BASE}/GestaoUsuario/ObterUsuarioPainelPorId?idUsuario=`,
		detalhesUsuarioEditar: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/BuscarDetalhesUsuario/`,
		relatorioUsuario: `${process.env.VUE_APP_API_BASE}/Relatorio/Usuarios`,
		atualizarUsuario: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/AtualizarCadastro`,
		buscaStatus: `${process.env.VUE_APP_API_BASE}/Usuario/ComboStatus`,
		novoUsuarioAdmin: `${process.env.VUE_APP_API_BASE}/Usuario/CadastroAdmin`,
		novoUsuarioParticipante: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/CadastrarUsuarioSemCaptcha`,
		buscarOcorrenciaUsuario: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/BuscarOcorrenciaUsuario`,
		perfilAcesso: `${process.env.VUE_APP_API_BASE}/GestaoUsuario/ComboPerfilAcesso`,
		historicoUsuarioCargo: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/HistoricoUsuarioCargo`,
		historicoUsuarioStatus: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/HistoricoUsuarioStatus`,
		historicoUsuarioAutoriadas: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/HistoricoUsuarioAutorizadas`,
		listarUsuariosEspelho: `${process.env.VUE_APP_API_BASE}/UsuarioParticipante/ListaUsuarios`,
		listaCargoAgrupado: `${process.env.VUE_APP_API_BASE}/Usuario/ComboCargoAgrupado`,
		listaCargoPorAutorizada: `${process.env.VUE_APP_API_BASE}/Usuario/ComboCargoPorTipoAutorizadaId`,
		listarCargos: `${process.env.VUE_APP_API_BASE}/Usuario/ComboCargo`,
		listarStatus: `${process.env.VUE_APP_API_BASE}/Usuario/ComboStatus`,
		pontuarUsuario: `${process.env.VUE_APP_API_BASE}/ExtratoPontuacao/Pontuar`,

		// Produto
		listarProduto: `${process.env.VUE_APP_API_BASE}/Produto/Listar`,
		buscarProduto: `${process.env.VUE_APP_API_BASE}/Produto/BuscarPorId?id=`,
		buscarProdutoCliente: `${process.env.VUE_APP_API_BASE}/Produto/ListarPorCliente?clienteId=`,
		cadastrarProduto: `${process.env.VUE_APP_API_BASE}/Produto/Cadastrar`,
		editarProduto: `${process.env.VUE_APP_API_BASE}/Produto/Editar`,
		obterPontuacaoProdutosDoCupom: `${process.env.VUE_APP_API_BASE}/Cupom/ObterPontuacaoProdutosDoCupom`,

		// Promoções
		listarPromocoes: `${process.env.VUE_APP_API_BASE}/Promocao/Listar`,
		buscarPromocoes: `${process.env.VUE_APP_API_BASE}/Promocao/BuscarPorId?id=`,
		buscarMecanicas: `${process.env.VUE_APP_API_BASE}/Mecanica/ListarTiposDeMecanica`,
		cadastrarPromocoes: `${process.env.VUE_APP_API_BASE}/Promocao/Cadastrar`,
		promocaoPromoshot: `${process.env.VUE_APP_API_BASE}/PromocaoPromoShot/Listar`,
		editarPromocoes: `${process.env.VUE_APP_API_BASE}/Promocao/Atualizar`,

		// Fale Conosco
		filtrarFaleConosco: `${process.env.VUE_APP_API_BASE}/Sac/Listar`,
		buscarFaleConosco: `${process.env.VUE_APP_API_BASE}/Sac/BuscarPorId?id=`,
		alterarStatusFaleConsoco: `${process.env.VUE_APP_API_BASE}/Sac/AtualizarStatusOcorrencia`,

		//SAC
		listarSacOcorrencias: `${process.env.VUE_APP_API_BASE}/Sac/ListaOcorrencias`,
		listarDetalhesOcorrenciaId: `${process.env.VUE_APP_API_BASE}/Sac/ListarSacMensagens`,
		atualizarDetalhesOcorrencia: `${process.env.VUE_APP_API_BASE}/Sac/AtualizarSacOcorrencia`,
		cadastrarMensagemOcorrencia: `${process.env.VUE_APP_API_BASE}/Sac/CadastrarMensagem`,
		listarSacMensagemDestinatario: `${process.env.VUE_APP_API_BASE}/Sac/ListarSacMensagemDestinatario`,
		listarSacStatus: `${process.env.VUE_APP_API_BASE}/Sac/ListarSacStatus`,
		listarSacAssunto: `${process.env.VUE_APP_API_BASE}/Sac/ListarSacAssunto`,
		listarSacMeioDeContato: `${process.env.VUE_APP_API_BASE}/Sac/ListarSacOcorrenciaContato`,
		cadastrarOcorrencia: `${process.env.VUE_APP_API_BASE}/Sac/CadastrarOcorrencia`,

		// Pedido
		filtrarRelatorioResgate: `${process.env.VUE_APP_API_BASE}/Pedido/FiltrarPedidos`,
		RelatorioResgate: `${process.env.VUE_APP_API_BASE}/Pedido/ExportarRelatorio`,

		// Prestação de contas
		relatorioPrestacaoDeContas: `${process.env.VUE_APP_API_BASE}/Relatorio/PrestacaoContas`,

		// Banners
		listarBanner: `${process.env.VUE_APP_API_BASE}/Banner/ListarTodos`,
		cadastrarBanner: `${process.env.VUE_APP_API_BASE}/Banner/Cadastrar`,
		buscarBanner: `${process.env.VUE_APP_API_BASE}/Banner/Cadastrar`,
		editarBanner: `${process.env.VUE_APP_API_BASE}/Banner/Atualizar`,
		obterDetalhesBanner: `${process.env.VUE_APP_API_BASE}/Banner/ObterBannerPorId?id=`,
		
		// Assistência
		listarCnpj: `${process.env.VUE_APP_API_BASE}/Assistencias/ListarCnpj`,

		// Autorizadas
		listarGrupos: `${process.env.VUE_APP_API_BASE}/Autorizada/ComboGrupo`,
		listarRegioes: `${process.env.VUE_APP_API_BASE}/Autorizada/ComboRegiao`,
		listarTipo: `${process.env.VUE_APP_API_BASE}/Treinamentos/ComboTipo`,
		listarComboTipo: `${process.env.VUE_APP_API_BASE}/Autorizada/ComboTipo`,
		listarAutorizadas: `${process.env.VUE_APP_API_BASE}/Autorizada/ComboAutorizada?idTipo=`,
		listarAutorizadasCompleto: `${process.env.VUE_APP_API_BASE}/Autorizada/ComboAutorizadaCompleto?idTipo=`,
		listarAutorizadasCadastradas: `${process.env.VUE_APP_API_BASE}/Autorizada/ListaAutorizadas`,
		listarDetalhesAutorizada: `${process.env.VUE_APP_API_BASE}/Autorizada/ObterDetalhes/`,
		atualizarAutorizada: `${process.env.VUE_APP_API_BASE}/Autorizada/Atualizar/`,
		cadastrarAutorizada: `${process.env.VUE_APP_API_BASE}/Autorizada/Cadastrar`,
		comboGrupo: `${process.env.VUE_APP_API_BASE}/Autorizada/ComboGrupo`,
		autorizadaGrupo: `${process.env.VUE_APP_API_BASE}/Autorizada/ComboAutorizadaGrupo`,

		// Campanhas
		tituloCampanhas: `${process.env.VUE_APP_API_BASE}/Campanha/ComboCampanhas`,
		adicionarPlanilhaCampanhas: `${process.env.VUE_APP_API_BASE}/Upload/EnviarPlanilhaCampanhas`,
		listarCampanhas: `${process.env.VUE_APP_API_BASE}/Campanha/Listar`,
		listarCampanhasFiltro: `${process.env.VUE_APP_API_BASE}/Campanha/ListarProAdmin`,
		cadastrarCampanha: `${process.env.VUE_APP_API_BASE}/Campanha/Cadastrar`,
		atualizarCampanha: `${process.env.VUE_APP_API_BASE}/Campanha/Atualizar`,
		detalhesCampanha: `${process.env.VUE_APP_API_BASE}/Campanha/DetalhesAdmin/`,
		listarComboCampanhas: `${process.env.VUE_APP_API_BASE}/Campanha/ComboCampanhas`,
		listarCampanhasApuracao: `${process.env.VUE_APP_API_BASE}/Campanha/ListarCampanhasParticipante`,

		// Prêmios
		listaPremios: `${process.env.VUE_APP_API_BASE}/Premios`,
		buscaPremiosPorCampanha: `${process.env.VUE_APP_API_BASE}/Premios/Campanha`,
		cadastroPremio: `${process.env.VUE_APP_API_BASE}/Premios/Cadastrar`,
		atualizaPremio: `${process.env.VUE_APP_API_BASE}/Premios/Atualizar`,
		getPremioPorId: `${process.env.VUE_APP_API_BASE}/Premios`,

		// Treinamentos
		adicionarPlanilhaTreinamentos: `${process.env.VUE_APP_API_BASE}/Upload/EnviarPlanilhaApuracao`,
		listarTreinamentosApuracao: `${process.env.VUE_APP_API_BASE}/Treinamentos/ListarTreinamentosParticipante`,
		listarTreinamento: `${process.env.VUE_APP_API_BASE}/Treinamentos/ListarTreinamentos`,
		listarTipoTreinamento: `${process.env.VUE_APP_API_BASE}/Treinamentos/ComboTipoTreinamento`,
		cadastrarTreinamento: `${process.env.VUE_APP_API_BASE}/Treinamentos/CadastrarTreinamento`,
		listarNomeTreinamento: `${process.env.VUE_APP_API_BASE}/Treinamentos/ComboNomeTreinamento`,
		atualizarTreinamento: `${process.env.VUE_APP_API_BASE}/Treinamentos/AtualizarTreinamento`,
		ObterTreinamento: `${process.env.VUE_APP_API_BASE}/Treinamentos/ObterTreinamento?id=`,

		// Notificações
		listarNotificacoes: `${process.env.VUE_APP_API_BASE}/Notificacoes/AdminListar`,
		listarTipoNotificacoes: `${process.env.VUE_APP_API_BASE}/Notificacoes/ComboTipoNotificacao`,
		adicionarNotificacao: `${process.env.VUE_APP_API_BASE}/Notificacoes/Cadastrar/`,
		obterDetalhes: `${process.env.VUE_APP_API_BASE}/Notificacoes/ObterDetalhes?id=`,
		adicionarSegmentacao: `${process.env.VUE_APP_API_BASE}/Notificacoes/AddSegmentacao`,
		listaSegmentacaoDetalhes: `${process.env.VUE_APP_API_BASE}/Notificacoes/ListarSegmentacaoDeDetalhes?=`,
		filtroSegmentacoes: `${process.env.VUE_APP_API_BASE}/Notificacoes/FiltraSegmentacaoDeDetalhes`,

		// Performance
		adicionarPlanilhaRanking: `${process.env.VUE_APP_API_BASE}/Upload/EnviarPlanilhaApuracao`,
		adicionarPlanilhaPerformance: `${process.env.VUE_APP_API_BASE}/Upload/EnviarPlanilhaApuracao`,
		listarRankingsApuracao: `${process.env.VUE_APP_API_BASE}/HistoricoPerformance/ListarRankingUsuario`,
		listarPerformancesApuracao: `${process.env.VUE_APP_API_BASE}/HistoricoPerformance/ListarKpisUsuario`,
		comboKpis: `${process.env.VUE_APP_API_BASE}/Performance/ComboKpis`,
		obterRanking: `${process.env.VUE_APP_API_BASE}/Performance/ObterRanking`,

		//Extrato
		listarOrigemPontuacao: `${process.env.VUE_APP_API_BASE}/ExtratoPontuacao/ListarOrigemPontuacao`,

		//Banners
		cadastrarBannerHome: `${process.env.VUE_APP_API_BASE}/Banner/Cadastrar`,
		listarBannersHome: `${process.env.VUE_APP_API_BASE}/Banner/Listar`,
		atualizarBannerHome: `${process.env.VUE_APP_API_BASE}/Banner/Atualizar`,
		tipoDeFiltro: `${process.env.VUE_APP_API_BASE}/Banner/ComboAbrangencias`,

		//Parâmetros
		mudarStatus: `${process.env.VUE_APP_API_BASE}/ParametroSistema/MudarSatus`,
		listarStatusCatalogo: `${process.env.VUE_APP_API_BASE}/ParametroSistema/ListarTodos`,
		verificarStatusAtual: `${process.env.VUE_APP_API_BASE}/ParametroSistema/ObterPorId`,
		buscaHistoricoId:  `${process.env.VUE_APP_API_BASE}/ParametroSistema/ObterDetalhes`
	};
}
