export default {
  namespaced: true,
  state: {
    listaProdutoCarregado: false,
    listaProduto: null,
  },
  mutations: {
    SET_CARREGADO(state, valor) {
      state.listaProdutoCarregado = valor;
    },
    SET_PRODUTO(state, data) {
      state.listaProduto = data;
    },
  },
};
