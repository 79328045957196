export default {
  namespaced: true,
  state: {
    estaLogado: false,
    listaUsuarioCarregado: false,
    relatorioLoading: false,
    relatorio: [],
    nomeUsuario: '',
    idUsuario: '',
    perfilRelatorio: null,
  },
  mutations: {
    SET_LOGADO(state, logado) {
      state.estaLogado = logado;
    },
    SET_PERFIL_RELATORIO(state, estado) {
      state.perfilRelatorio = estado;
    },
    SET_CARREGADO(state, valor) {
      state.listaUsuarioCarregado = valor;
    },
    SET_LOADING_RELATORIO(state, valor) {
      state.relatorioLoading = valor;
    },
    SET_NOME(state, nome) {
      state.nomeUsuario = nome;
    },
    SET_ID_USUARIO(state, id) {
      state.idUsuario = id;
    },
    SET_RELATORIO(state, relatorio) {
      state.relatorio = relatorio;
    },
  }
};
