export default {
  namespaced: true,
  state: {
    listaCupomCarregado: false,
    listaCupom: null,
    modal: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: "",
      lista: [],
    }
  },
  mutations: {
    SET_CARREGADO(state, valor) {
      state.listaCupomCarregado = valor;
    },
    SET_CUPOM(state, data) {
      state.listaCupom = data;
    },
    SET_MODAL(state, modal) {
      state.modal.modalAtivado = modal.ativado;
      state.modal.mensagemModal = modal.mensagem;
      state.modal.tipoModal = modal.tipo;
      state.modal.lista = modal.lista;
    }
  },
};
