import Login from "@/views/Login.vue";

const routes = [
    {
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			perfilPermitido: [null, undefined, 2, 3, 4, 5],
			precisaAutenticacao: false
		}
	},
]

export default routes