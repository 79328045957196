import Home from "@/views/logadas/Home.vue";

const routes = [
    {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
            precisaAutenticacao: true,
            perfilPermitido: [2, 3, 4, 5],
            menuInterno: {
                texto: "Home",
                icone: "IconInicio"
            }
        }
    },
]

export default routes