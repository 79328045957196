import Autorizadas from "@/views/logadas/autorizadas/_Autorizadas.vue";

const routes = [
    {
        path: "/autorizadas",
        name: "Autorizadas",
        component: Autorizadas,
        redirect: "/autorizadas/listagem",
        meta: {
            precisaAutenticacao: true,
            menuInterno: {
                texto: "Acesso a listagem das autorizadas",
                icone: "IconEspelho"
            }
        },
        children: [
            {
                path: "listagem",
                name: "listar-autorizadas",
                component: () =>
                    import("@/views/logadas/autorizadas/AutorizadasListar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "editar/:id",
                name: "detalhes-autorizada",
                component: () =>
                    import("@/views/logadas/autorizadas/AutorizadasEditar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
            {
                path: "adicionar",
                name: "adicionar-autorizada",
                component: () =>
                    import("@/views/logadas/autorizadas/AutorizadasAdicionar.vue"),
                meta: {
                    perfilPermitido: [2, 5],
                    precisaAutenticacao: true
                }
            },
        ]
    },
]

export default routes